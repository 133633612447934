// note: i18n by nuxt-i18n
// ref: https://v8.i18n.nuxtjs.org/
import type { NuxtI18nOptions } from '@nuxtjs/i18n/dist/module'
import en from '../locales/en.json'
import ja from '../locales/ja.json'
import { getSingleCookieValue } from './_vaf/storage-control'

const jaLanguage = 'ja'
const enLanguage = 'en'
const cookieKey = 'VUEI18N_MANUAL_LOCALE'
const isBrowserLanguageJa = process.client
  ? window.navigator?.language?.startsWith(jaLanguage)
  : false
const isBrowserLanguageEn = process.client
  ? window.navigator?.language?.startsWith(enLanguage)
  : false
const defaultLanguageFromCookie = process.client
  ? getSingleCookieValue(cookieKey)
  : ''
const defaultLanguage =
  defaultLanguageFromCookie === jaLanguage
    ? jaLanguage
    : defaultLanguageFromCookie === enLanguage
    ? enLanguage
    : isBrowserLanguageJa
    ? jaLanguage
    : isBrowserLanguageEn
    ? enLanguage
    : jaLanguage

// settings for nuxt-i18n v9~
export const nuxtI18nOptions: NuxtI18nOptions = {
  strategy: 'prefix_and_default',
  langDir: './locales/',
  locales: [
    {
      code: jaLanguage,
      iso: 'ja-JP',
      file: 'ja.json',
      isCatchallLocale: true,
    },
    {
      code: enLanguage,
      iso: 'en-US',
      file: 'en.json',
    },
  ],
  defaultLocale: defaultLanguage,
  customRoutes: 'config',
  pages: {
    api: false,
    server: false,
  },
  detectBrowserLanguage: {
    useCookie: true,
    cookieKey: 'i18n_redirected',
    redirectOn: 'root', // recommended
    alwaysRedirect: true,
    cookieCrossOrigin: true,
    fallbackLocale: defaultLanguage,
  },
  vueI18n: './src/utils/i18n.ts',
}

export default {
  legacy: false,
  locale: defaultLanguage,
  messages: {
    ja,
    en,
  },
}
